/* ErrorBanner.css */
.operation-error-banner {
  background-color: #fff9c4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-left: 8px solid #ff9800;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.operation-error-banner-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.operation-error-banner-header .icon {
  margin-right: 10px;
}

.operation-error-banner ul {
  padding-left: 0;
}

.operation-error-banner ul li {
  margin-bottom: 5px;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.operation-error-banner ul li .list-icon {
  margin-right: 10px;
}
