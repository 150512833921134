.category-titre {
  margin-bottom: 1.5rem;
  width: 100%;

  height: fit-content;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #1d6f95;
  display: flex;
  align-items: center;
  gap: 2rem;
  &:before {
    content: " ";
    flex: 1 1;
    position: relative;
    height: 2px;
    background-color: #ffb749;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
  &:after {
    content: " ";
    flex: 1 1;
    position: relative;
    height: 2px;
    background-color: #ffb749;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
}
.rayon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  .rayon-card {
    width: 300px;
    height: 300px;
    background-color: #ffb749;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
      h3 {
        background-color: rgba(255, 255, 255, 0.9);
      }
    }
    img {
      width: 100%;
      height: 75%;
      object-fit: cover;
    }
    h3 {
      position: absolute;
      bottom: 6%;
      left: 50%;
      transform: translateX(-50%);
      color: #333;
      font-size: 0.9em;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 6px 11px;
      border-radius: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%;
    }
  }
}
