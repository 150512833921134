.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 400px;
  position: relative;
}

.confirmation-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 10px;
  gap: 30px;
}

.confirmation-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.confirmation-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.confirmation-modal-body {
  padding: 10px 0;
}

.confirmation-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin-top: 10px;
  gap: 15px;
}

.confirmation-modal-footer button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.confirmation-add-button {
  background-color: #d9534f;
  color: white;
}

.floating-label {
  position: relative;
  margin-bottom: 15px;
}

.floating-label input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #bcc2ca;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #f8fafc;
}

.floating-label input:hover {
  border-color: #364152;
}

.floating-label input:focus {
  border-color: #2196f3;
  outline: none;
}

.floating-label label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f8fafc;
  padding: 0 5px;
  color: #aaa;
  transition: 0.2s;
  pointer-events: none;
}

.floating-label input:focus + label,
.floating-label input:not(:placeholder-shown) + label {
  top: -10px;
  left: 8px;
  font-size: 0.75rem;
  color: #2196f3;
}

.confirmation-modal-body input[type="checkbox"] {
  margin-top: 0;
  width: auto;
}

.error-message {
  color: red;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  // margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
}
