.titre-panier {
  margin-bottom: 1.5rem;
  width: 100%;

  height: fit-content;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #1d6f95;
  display: flex;
  align-items: center;
  gap: 2rem;
  &:before {
    content: " ";
    flex: 1 1;
    position: relative;
    height: 2px;
    background-color: #ffb749;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
  &:after {
    content: " ";
    flex: 1 1;
    position: relative;
    height: 2px;
    background-color: #ffb749;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
}
.fiche-produit-card {
  position: relative;
  max-width: 1500px;
  height: 800px;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  flex-direction: row;

  .fiche-image-container,
  .fiche-produit-info {
    flex: 1;
    // display: inline-block;
    width: 50%;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .fiche-image-container {
    position: relative;
    //padding-bottom: 50px;
    height: 100%;

    .promo-label {
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: #d51a58;
      padding: 5px 10px;
      color: #fff;
      font-weight: bold;
      border-radius: 3px;
      z-index: 10;
    }
  }

  img {
    object-fit: cover;
  }

  .fiche-produit-info {
    padding: 20px;

    .select-container {
      margin: 10px 0;
      display: flex;
      gap: 30px;
    }

    .custom-select {
      position: relative;
      display: inline-block;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid #555;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }

      select {
        padding: 12px 30px 12px 20px;
        font-size: 1em;
        background-color: #f7f7f7;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        outline: none;
      }
    }

    .quantity-container {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .quantity-container input[type="number"] {
      width: 50px;
      padding: 10px;
      text-align: center;
    }

    .add-to-cart-btn {
      background-color: #2c3e50;
      padding: 12px 20px;
      font-size: 1em;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      color: #fff;
      border: none;
      border-radius: 5px;
      font-weight: 600;
      transition: background-color 0.3s ease;
      width: 80%;
      cursor: pointer;
      &:hover {
        background-color: #49a6c2;
      }
    }
    .price,
    .old-price,
    .promo-price {
      display: inline-block;
      vertical-align: middle;
    }

    .old-price {
      font-size: 1.1em;
      text-decoration: line-through;
      color: #888;
      margin-right: 5px;
    }

    .promo-price {
      font-size: 1.2em;
      font-weight: bold;
      color: #d51a58;
    }
    h2 {
      font-size: 24px; // Augmentation de la taille de la police
      font-weight: bold; // Plus audacieux
      margin-bottom: 20px; // Plus d'espacement
      color: #333;
    }
    h3 {
      font-size: 20px; // Augmentation de la taille de la police
      font-weight: bold; // Plus audacieux
      margin-top: 30px; // Plus d'espacement par rapport à l'élément précédent
      margin-bottom: 10px; // Espacement sous le titre
      color: #2c3e50; // Couleur similaire au titre principal
    }

    p.description {
      font-size: 0.9em;
      color: #777;
      margin-bottom: 10px;
    }

    p.price {
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 10px;
      color: #2c3e50;
    }

    p.condition {
      font-size: 0.85em;
      color: #555;
      margin-bottom: 10px;
    }

    p.promo {
      font-size: 1.1em;
      color: #d51a58;
      text-decoration: line-through;
      margin-right: 5px;
      display: inline-block;
    }

    p.shipping {
      font-size: 0.85em;
      color: #5bc0de;
      margin-bottom: 10px;
    }

    .detail-btn {
      margin-top: 10px;
      background-color: #5bc0de;
      color: #fff;
      border: none;
      padding: 8px 12px;
      border-radius: 5px;
      font-size: 0.9em;
      font-weight: 600;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: #49a6c2;
      }
    }
    .error-message {
      color: red;
      margin-top: 10px;
    }
    .confirmation-message {
      color: green;
      font-weight: bold;
      margin: 10px 0;
    }
  }
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Espace entre les miniatures */
  margin-top: 10px;
}

.thumbnail-container img {
  width: 60px; /* Vous pouvez ajuster la taille comme vous le souhaitez */
  height: 60px;
  cursor: pointer;
  border: 2px solid transparent; /* Pour que l'image ne se déplace pas lors de la sélection */
  transition: border-color 0.3s ease;
}

.thumbnail-container .active-thumbnail {
  border-color: #5bc0de; /* Couleur de la bordure pour l'image active */
}

//Modal
.modal {
  position: fixed; /* Positionnement fixe par rapport à la fenêtre du navigateur */
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  z-index: 1000; /* S'assure que la modal est au-dessus des autres éléments */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 300px; /* Largeur minimale */
}
.modal-content button {
  margin-top: 20px; /* Espace au-dessus des boutons */
}

.modal-content .button {
  //display: block; /* Les rend block pour occuper toute la largeur */
  width: calc(50% - 10px); /* Calcule la largeur pour deux boutons */
  margin-top: 20px; /* Petite marge entre les boutons */
  //padding: 10px 20px; /* Padding pour les boutons */
  text-align: center; /* Texte centré */
  text-decoration: none; /* Enlève le soulignement des liens */
  color: white; /* Couleur du texte */
  background-color: #007bff; /* Couleur de fond */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.modal-content .button:hover {
  background-color: #0056b3; /* Couleur de fond au survol */
}
.modal-content .gris {
  background-color: rgb(131, 131, 131); /* Couleur de fond */
}
.modal-content .gris:hover {
  background-color: rgb(68, 68, 68); /* Couleur de fond */
}

.modal-button-container {
  display: flex;
  justify-content: space-between; /* Répartit les boutons sur les côtés */
}
