.operation-container {
  padding: 10px;

  .CmNationale-titre {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
  }

  .operation-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .block {
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background-color: #fcf2d4;
      &.small {
        width: calc(33% - 10px);
      }

      &.medium {
        width: calc(50% - 10px);
      }

      &.large {
        width: calc(100% - 10px);
      }

      h3 {
        font-size: 17px;
        font-weight: 700;
        color: #1d6f95;
        text-align: center;
      }
      h5 {
        font-size: 12px;
        text-align: center;
        color: #333;
        margin-bottom: 10px;
      }
    }

    .title-operation {
      display: flex;
      flex-direction: column;
      gap: 10px;

      flex-grow: 1;
      min-width: calc(33% - 10px);
      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;

        .left-selection,
        .right-selection {
          flex: 1;
        }

        .left-selection {
          max-width: 50%;
        }

        .right-selection {
          max-width: 50%;
        }

        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;

          label {
            font-size: 14px;
            color: #666;
            margin-bottom: 3px;
          }

          .input-qte,
          .styled-select,
          input[type="date"],
          textarea {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            transition: border-color 0.2s;
            cursor: pointer;
            &:focus {
              border-color: #007bff;
            }
          }
          option {
            padding: 8px;
          }
        }

        .other-date-inputs {
          display: flex;
          flex-direction: column;
          gap: 5px;

          label {
            font-size: 14px;
            color: #666;
          }
        }
      }

      .footer-section {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          font-size: 14px;
          color: #666;
          margin-bottom: 3px;
        }

        textarea {
          width: 100%;
          padding: 5px;
          border-radius: 4px;
          border: 1px solid #ccc;
          font-size: 14px;
          resize: vertical;
          cursor: pointer;
          &:focus {
            border-color: #007bff;
          }
        }
      }
    }
    .distribution {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;

        .left-selection,
        .right-selection {
          flex: 1;
        }

        .left-selection {
          max-width: 50%;
        }

        .right-selection {
          max-width: 50%;
        }

        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
          label {
            font-size: 14px;
            color: #666;
            margin-bottom: 3px;
          }
          .styled-select,
          input,
          textarea {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            background-color: #fff;
            cursor: pointer;
            width: 100%;
            box-sizing: border-box;
            transition: border-color 0.2s;

            &:focus {
              border-color: #007bff;
            }
          }
          option {
            padding: 8px;
          }
        }
      }

      .footer-section {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          font-size: 14px;
          color: #666;
          margin-bottom: 3px;
        }

        input {
          padding: 6px;
          border-radius: 4px;
          border: 1px solid #ccc;
          font-size: 14px;
          width: 100%;
          box-sizing: border-box;
          background-color: #fff;
          transition: border-color 0.2s;
          cursor: pointer;
          &:focus {
            border-color: #007bff;
          }
        }

        textarea {
          width: 100%;
          padding: 5px;
          border-radius: 4px;
          border: 1px solid #ccc;
          font-size: 14px;
          resize: vertical;
          cursor: pointer;
          &:focus {
            border-color: #007bff;
          }
        }
      }
    }
    .plv {
      display: flex;
      flex-direction: column;
      gap: 10px;

      flex-grow: 1;
      min-width: calc(33% - 10px);

      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;
        .left-selection,
        .right-selection {
          flex: 1;
        }
        .left-selection {
          max-width: 65%;
        }
        .right-selection {
          max-width: 35%;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;

          input {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            transition: border-color 0.2s;
            cursor: pointer;
            &:focus {
              border-color: #007bff;
            }
          }

          label {
            font-size: 14px;
            color: #666;
            padding: 8px;
          }
        }
      }
    }
    .sms-relais-depliant {
      display: flex;
      flex-direction: column;
      gap: 10px;

      flex-grow: 1;
      min-width: calc(33% - 10px);
      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;
        .left-selection,
        .right-selection {
          flex: 1;
        }
        .left-selection {
          max-width: 50%;
        }
        .right-selection {
          max-width: 50%;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
          label {
            font-size: 14px;
            color: #666;
            margin-bottom: 3px;
          }
          input,
          .styled-select {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            transition: border-color 0.2s;
            cursor: pointer;

            &:focus {
              border-color: #007bff;
            }
          }
        }
      }
      .footer-section {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        label {
          font-size: 14px;
          color: #666;
          margin-bottom: 3px;
        }
      }
    }
    .sms-location-bdd {
      display: flex;
      flex-direction: column;
      gap: 10px;

      flex-grow: 1;
      min-width: calc(33% - 10px);
      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;
        .left-selection,
        .right-selection {
          flex: 1;
        }
        .left-selection {
          max-width: 50%;
        }
        .right-selection {
          max-width: 50%;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
          label {
            font-size: 14px;
            color: #666;
            margin-bottom: 3px;
          }
          input,
          .styled-select {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            transition: border-color 0.2s;
            cursor: pointer;

            &:focus {
              border-color: #007bff;
            }
          }
        }
      }
      .footer-section {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        label {
          font-size: 14px;
          color: #666;
          margin-bottom: 3px;
        }
      }
    }
    .sms-client-fid {
      display: flex;
      flex-direction: column;
      gap: 10px;

      flex-grow: 1;
      min-width: calc(33% - 10px);
      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;

        .left-selection,
        .right-selection {
          flex: 1;
        }
        .left-selection {
          max-width: 50%;
        }
        .right-selection {
          max-width: 50%;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
          label {
            font-size: 14px;
            color: #666;
            margin-bottom: 3px;
          }
          input,
          .styled-select {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            transition: border-color 0.2s;
            cursor: pointer;

            &:focus {
              border-color: #007bff;
            }
          }
        }
      }
      .footer-section {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        label {
          font-size: 14px;
          color: #666;
          margin-bottom: 3px;
        }
      }
    }
    .bonial {
      display: flex;
      flex-direction: column;

      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;

        .left-selection,
        .right-selection {
          flex: 1;
        }
        .left-selection {
          max-width: 50%;
        }
        .right-selection {
          max-width: 50%;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
          input,
          .styled-select {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            transition: border-color 0.2s;
            cursor: pointer;
            &:focus {
              border-color: #007bff;
            }
          }
          label {
            font-size: 14px;
            color: #666;
            padding: 8px;
          }
        }
      }
    }
    .generalCarnet {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;

        .left-selection,
        .right-selection {
          flex: 1;
        }
        .left-selection {
          max-width: 50%;
        }
        .right-selection {
          max-width: 50%;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
          input,
          .styled-select {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            transition: border-color 0.2s;
            cursor: pointer;
            &:focus {
              border-color: #007bff;
            }
          }
          label {
            font-size: 14px;
            color: #666;
            padding: 8px;
          }
        }
      }
      .footer-section {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          font-size: 14px;
          color: #666;
          margin-bottom: 3px;
        }
        textarea {
          width: 100%;
          padding: 5px;
          border-radius: 4px;
          border: 1px solid #ccc;
          font-size: 14px;
          resize: vertical;
          cursor: pointer;
          &:focus {
            border-color: #007bff;
          }
        }
      }
    }
    .personnalisation {
      display: flex;
      flex-direction: column;

      .selection-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-top: 10px;

        .left-selection,
        .right-selection {
          flex: 1;
        }
        .left-selection {
          max-width: 50%;
        }
        .right-selection {
          max-width: 50%;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
          label {
            font-size: 14px;
            color: #666;
            margin-bottom: 3px;
          }
          .styled-select {
            padding: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 14px;
            background-color: #fff;
            cursor: pointer;
            width: 100%;
            box-sizing: border-box;
            transition: border-color 0.2s;

            &:focus {
              border-color: #007bff;
            }
          }
        }
      }
      .footer-section {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          font-size: 14px;
          color: #666;
          margin-bottom: 3px;
        }
        textarea {
          width: 100%;
          padding: 5px;
          border-radius: 4px;
          border: 1px solid #ccc;
          font-size: 14px;
          resize: vertical;
          cursor: pointer;
          &:focus {
            border-color: #007bff;
          }
        }
      }
    }
    .budget {
      .table-budget {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .budget-table {
        width: 90%;
        border-collapse: collapse;
        margin: 10px 0;
        font-size: 14px;
      }

      .budget-table th,
      .budget-table td {
        padding: 6px 10px;
        border: 1px solid #ddd;
      }

      .budget-table th {
        background-color: #1d6f95;
        color: white;
        text-transform: uppercase;
      }

      .budget-table tr:nth-of-type(even) {
        background-color: #f9f9f9;
      }

      .budget-table tr:hover {
        background-color: #f1f1f1;
      }
      .budget-button-group {
        display: flex;
        flex-direction: column;
        width: 8%;

        .budget-button {
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 6px 12px;
          font-size: 14px;
          cursor: pointer;
          color: black;
          border: none;
          border-radius: 4px;
          align-self: center;
          transition: background-color 0.2s;
        }
        .budget-add {
          background-color: #bfc734;
        }
        .budget-add:hover {
          background-color: #8e9326;
        }
        .budget-delete {
          background-color: #fec46c;
        }
        .budget-delete:hover {
          background-color: #cc9d57;
        }
      }
    }
  }

  .bon-de-commande {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .bon-de-commande-btn {
      background-color: #1d6f95;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #134861;
      }
    }
  }
}
