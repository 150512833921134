@import "/src/styles/global.scss";

.home-layout {
  display: flex;
  height: 100%;
  width: 100%;

  &__content {
    flex: 1;
    padding: $padding-page;
  }
}

.promo-container {
  width: 1000px;
  height: 75px;
  background: linear-gradient(45deg, #d51a58, #b9074d);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }
  .promo-discount {
    font-size: 3em;
    font-weight: bold;
    color: #ffb749;
    text-align: left;
    padding: 10px;
  }
  .promo-description {
    text-align: center;
    color: #ffb749;
  }
}
