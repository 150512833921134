@import "/src/styles/global.scss";

.filled-link {
  padding: 0.5rem;
  height: 150px;
  width: 150px;
  border-radius: 4px;
  font-size: 1rem;
  gap: 0.5rem;
  box-shadow: 5px 5px 5px #1b1b1b;
  text-align: center;
  text-transform: uppercase;
  color: $color-text-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.01);
  }

  .libelle {
    display: flex;
    flex-direction: column;
    .sublibelle {
      font-size: 0.75em;
    }
  }

  &--blue {
    background-color: $color-primary;
  }
  &--green {
    background-color: $color-accent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Ombre du texte */
  }
}

.outlined-link {
  width: 150px;
  padding: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  color: $color-text-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid currentColor;

  &:hover {
    background-color: $color-accent-80;
  }

  &.selected-link {
    background-color: $color-accent;
    box-shadow: 5px 5px 5px #1b1b1b;
  }
}
