@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');

$font-primary: 'Poppins', sans_serif;

$color-primary: #1d6f95;
$color-primary-80: rgba($color-primary, 0.8);
$color-primary-30: rgba($color-primary, 0.3);
$color-primary-10: rgba($color-primary, 0.1);
$color-primary-variant: #1d6f95;
$color-secondary: #ffb749;
$color-secondary-80: rgba($color-secondary, 0.8);
$color-secondary-hover: #ffbf5b;
$color-grey: #d0d7de;
$color-accent: #bfc734;
$color-accent-80: rgba($color-accent, 0.8);
$color-complementary: #b9074d;
$color-background: #fff;
$color-background-login: #134b68;
$color-referencia: #c97434;
// Inputs
$color-border-input: #d0d7de;
$color-background-input: #f6f8fa;

$color-text: #000;
$color-text-light: #fff;
$color-text-muted: #656d76;
$color-text-title: #1d6f95;

$color-warning: #ff5b00;
$color-danger: #dc143c;

$color-link: #8492bf;
$color-link-hover: #a0b1e4;

$color-excel: #02723b;
$color-pdf: #b30b00;
$color-disabled: #bac0cc;
$color-white: #ffffff;
$color-black: #000;
$color-button: #64954e;
$color-button-hover: #7aae63;

$color-border-cell-table: #ddd;

$box-shadow-card: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
  rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
$box-shadow-medium: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
$border-radius-card: 15px;

$transition: all 400ms ease;
$padding-page: 2rem 1rem 4rem 1rem;
$font-size-title: 1.15rem;

$radius-input: 6px;
