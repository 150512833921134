.CmNationale-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  .CmNationale-card {
    width: 300px;
    height: 280px;
    background-color: #ffb749;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
      h3 {
        background-color: rgba(255, 255, 255, 0.9);
      }
    }
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    h3 {
      margin: 15px;
      color: #333;
      font-size: 0.9em;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 6px 11px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      white-space: normal;
      text-align: center;
      max-width: 90%;
    }
  }
}
