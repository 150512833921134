.accueil-carousel-wrap {
    width: 100%!important;
}

.link-type {
    display: block;
    color: white;
    margin: 10px 0;
    text-decoration: underline;
}


.fidelity-container {
    display: flex;

    .fidelity-main {
        width: calc(100% - 200px)
    }

    .fidelity-aside {
        width: 220px;
    }

    @media screen and (max-width:960px) {

        flex-direction: column;

        .fidelity-aside {
            width: 100%;
        }

        .fidelity-main {
            width: 100%
        }
        
    }
}