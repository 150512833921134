.mes-engagements-container {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;

  .table-title {
    color: #1d6f95;
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
  }

  .engagements-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    th,
    td {
      padding: 12px 15px;
      border: 1px solid #ddd;
    }

    td {
      padding: 5px 15px;
    }

    th {
      padding: 8px 15px;
      background-color: #1d6f95;
      color: white;
      text-transform: uppercase;
    }

    .total-column {
      background-color: #ffc56d;
      text-align: right;
    }

    .view-column {
      text-align: center;
      font-size: 15px;
      color: #b9074d;
    }

    .engagements-table .total-row {
      background-color: #1d6f95 !important;
      color: white !important;
      font-weight: bold;
    }

    .total-row {
      background-color: #1d6f95;
      color: white;
      font-weight: bold;

      .total-cell {
        background-color: #ffc56d;
        color: black;
        text-align: right;
      }
    }

    .action-column {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .deleteButton {
      background-color: #b9074d;
      color: white;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #97053f;
      }
    }

    .updateButton {
      background-color: #207aa5;
      color: white;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #175a79;
      }
    }
  }

  .center-button {
    display: flex;
    justify-content: center;

    .engageButton {
      background-color: #bfc734;
      color: black;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.2s;
      margin-bottom: 20px;
      margin-top: 10px;

      &:hover {
        background-color: #8e9326;
      }
    }
  }

  .warning-message {
    display: flex;
    align-items: center;
    background-color: #fff9c4;
    color: black;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;

    svg {
      margin-right: 10px;
    }
  }
}

.aucun-container {
  padding: 20px;
  background-color: #1d6f95;
  color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

.error-message {
  color: red;
  font-size: 12px;
  text-align: center;
}

.fade-enter {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.fade-exit-active {
  opacity: 0;
}