.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  position: relative;
}

.confirmation-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 10px;
  gap: 30px;
}

.confirmation-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.confirmation-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.confirmation-modal-body {
  padding: 10px 0;
}

.confirmation-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin-top: 10px;
  gap: 15px;

  button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .confirmation-cancel-button {
    background-color: #f0f0f0;
    color: #333;
  }

  .confirmation-add-button {
    background-color: #1d6f95;
    color: white;
    &:hover {
      background-color: #055275;
    }
  }
}

.info-container {
  display: flex;
  align-items: center;
  border: 2px solid #ffd700;
  background-color: #fffacd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.warning-icon {
  font-size: 4rem; /* Agrandissement de l'icône */
  color: #ffd700;
  margin-right: 10px;
}

.info-text {
  display: flex;
  flex-direction: column;
}

.file-drop-container {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #999;
  }

  &:focus {
    border-color: #1d6f95;
    outline: none;
  }

  input[type="file"] {
    display: none;
  }

  p {
    margin: 0;
  }
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}
