.produit-titre {
  margin-bottom: 1.5rem;
  width: 100%;

  height: fit-content;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #1d6f95;
  display: flex;
  align-items: center;
  gap: 2rem;
  &:before {
    content: " ";
    flex: 1 1;
    position: relative;
    height: 2px;
    background-color: #ffb749;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
  &:after {
    content: " ";
    flex: 1 1;
    position: relative;
    height: 2px;
    background-color: #ffb749;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
}
.produits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  max-width: 1500px;
  margin: 0 auto;
}

.back-arrow {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #333;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
