@import '../../../styles/variables';

.fidelity-main {
    padding: 20px 50px;

    .fidelity-title {
        font-weight: bold;
        margin-bottom: 30px;

        span {
            text-transform: uppercase;
        }

        &::after {
            content : " ";
            display: block;

            height: 2px;
            width: 50px;
            background-color: $color-secondary;

        }
    }

    h4 {
        width: fit-content;
        padding: 5px 30px 5px 5px;
        margin-bottom: 20px;

        text-transform: uppercase;
        font-weight: bold;

        color: #fff;
        background-color: $color-referencia;

    }

    .video-container {

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .video-iframe {
            margin-bottom: 50px;
            text-align: center;
        }

        .video-legende {
            margin: 20px auto;
            width: 80%;
        }

        @media screen and (max-width: 1280px) {
            flex-direction: column;

            .video-iframe {
                iframe {
                    width: 100%;
                }
            }
        }
    }

    .fidelity-why-container {
        width : 80%;
        margin: auto;
        display: flex;

        .fidelity-why-content {
            padding: 10px 20px;
            width: 50%;

            &:first-child {
                border-right: 1px solid $color-referencia;
            }

            ul {
                padding: 0 15px;
                li {
                    list-style-type: '> ';
                    strong {
                        text-transform: uppercase;
                    }
                }
            }

            .info {
                margin-top: 10px ;
                text-transform: uppercase;
                color: $color-referencia;
            }
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column;

            .fidelity-why-content {
                padding: 10px 20px;
                width: 100%;
    
                &:first-child {
                    border-right: none
                }
            }
        }
    }

    .info-box {
        width: 60%;
        margin: 30px auto;
        padding: 10px 20px;

        border: 2px solid $color-referencia;

        border-radius: 20px;

        ul { 
            padding: 5px 15px;
            li {
                list-style-type: "- ";
            }
        }

        @media screen and (max-width: 1024px) {
            width: 80%;
        }
    }

    .fidelity-operating-container {
        display: flex;
        gap: 10px;
        width: 80%;
        margin: auto;

        .fidelity-operating-content {
            width: 50%;
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            width: 100%;

            .fidelity-operating-content {
                width: 100%;
            }
        }
    }
}

.fidelity-aside {
    min-height: 100dvh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 60px 20px;
    margin: 0 auto;

    background-color: $color-complementary;
    color: #fff;
    text-align: center;

    .fidelity-aside-button-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .spacer-button {
            a {
                margin-bottom: 10px;
            }
        }
    }

    .button-primary-type {
        display: block;
        padding: 5px 10px;

        width: 100%;

        text-transform: uppercase;
        text-align: center;
        
        color: #fff;

        border: 1px solid #fff
    }

    .button-secondary-type {
        display: block;

        padding: 5px 10px;

        width: 100%;

        text-decoration: none;
        text-transform: uppercase;
        text-align: center;

        color: #fff;
        background-color: $color-button;
        border: 1px solid #fff;
    }

    .fidelity-aside-address-container {
        p {
            margin: 10px auto;
        }

        &::after {
            content: " ";
            display: block;
            height: 1px;
            width: 50%;

            margin: 30px auto;

            background-color: #fff;
        }
    }
}

.more-button-container {
    width: 100%;
    max-width: 450px;
    margin: auto;

    @media screen and (max-width:960px) {
        width: 100%;
    }
}

.more-button {
    display: block;
    margin: auto;
    padding: 5px 50px;
    margin-top: 20px;

    text-transform: uppercase;

    color: #fff;
    background-color: $color-primary;
    border-radius: 10px;
    width: 100%;
}

.fid-popup-container {
    height: 100dvh;
    width: 100%;
    display: flex;
    justify-content: center;  /* Centre horizontalement */
    align-items: center;      /* Centre verticalement */

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    background-color: #fff;

    iframe {
        margin: auto;
    }

    .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1000;
    }
}

.fidelity-form-container {
    display: flex;
    &>div {
        width: 100%;
    }

    @media screen and (max-width:960px) {
        flex-direction: column;
    }


    input[type="text"], input[type="email"] {
        padding: 5px 10px;
        width: 100%;
        margin-bottom: 5px;
        border: 1px solid #999;
    }

    textarea {
        padding: 10px 10px;
        width: 100%;
        min-height: 40px;
        resize: none;
        border: 1px solid #999;
    }

    .header-paragraph {
        text-align: center;
        margin: 10px 0;
    }

    .form-section-title {
        text-transform: uppercase;
        margin: 20px 0 10px;
    }

    .fidelity-pack-container {
        .pack-info {
            display: flex;
            width: 100%;
            margin: 10px 0;

            p:first-child {
                width: 80%;
            }

            p:nth-child(2){
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }
        }

        .pack-legal {
            margin-top: 10px;

            text-align: center;
            font-size: 12px;

            p:nth-child(2) {
                margin: 10px 0;
            }
        }
    }

    .pack-form-container {
        padding: 10px 20px;

        background-color: #fff;
        border: 1px solid #999;
        border-radius: 10px;

        .container-pack-title {
            text-align: center;
            font-weight: bold;
            
        }

        .container-pack-form-content {
            display: flex;
            padding-top: 10px;

            border-top: 1px solid rgba(0,0,0, 0.2);

            .content-pack-center {
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .content-pack-title {
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }

    .legal-files-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        margin: 20px 0;
    }

    .legal-notice-container {
        margin-bottom: 20px;
        input[type="checkbox"] {
            margin-right: 10px;
        }
    }
}

.button-primary {
    background-color: $color-primary;
    color: #fff;
    padding: 2px 5px;
    border-radius: 5px;
    cursor: pointer;
}

.button-secondary {
    padding: 5px 20px;
    border: 1px solid #333;
    border-radius: 10px;
    background-color: $color-secondary;

    &:hover {
        background-color: $color-secondary-hover;
    }

    &:disabled {
        background-color: #eeeeee;
        opacity: 0.2;
    }
}

.button-link {
    color: $color-button;
    text-decoration: underline;
    &:hover {
        color: $color-button-hover
    }
}

.error-container {
    margin-bottom: 10px;
    p {
        color: $color-danger;
        margin-bottom: 5px;
    }
}