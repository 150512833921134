.zone-distribution-container {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.zoneSelect {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-size: 14px;
    color: #666;
    margin-bottom: 3px;
  }

  .styled-select {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    transition: border-color 0.2s;

    &:focus {
      border-color: #007bff;
    }
  }

  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .btn-action {
      background-color: #fec46c;
      color: black;
      padding: 5px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin: 5px;
      transition: background-color 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 5px;
      }

      &:hover {
        background-color: #fcad36;
      }
    }
  }
}

.table-title {
  color: #1d6f95;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
}

.scroll {
  max-height: 600px;
  overflow-y: auto;
}
.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #1d6f95;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-track {
  background-color: #f9f9f9;
  border-radius: 4px;
}

.scroll {
  scrollbar-width: thin;
  scrollbar-color: #1d6f95 #f9f9f9;
}
// .distribution-table thead th {
//   padding: 5px 10px;
//   font-size: 12px;
//   line-height: 1;
// }
.distribution-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  thead {
    position: sticky;
    top: 0;
    background-color: #1d6f95;
    color: white;
    text-transform: uppercase;
    z-index: 2;
    th {
      padding: 5px 10px;
      line-height: 1;
    }
  }
  .distribution-thead {
    background-color: #1d6f95;
  }
  th,
  td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }
  .total-row {
    background-color: #1d6f95;
    color: white;
    font-weight: bold;
    position: sticky;
    bottom: 0;
    z-index: 1;
  }

  td {
    padding: 5px 15px;
  }
}

.button-action.deleteButton {
  z-index: 0;
}

.table-container-dist {
  max-width: 1200px;
  margin: 0 auto;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  button {
    padding: 5px 10px;
    font-size: 14px;
  }

  span {
    line-height: 30px;
  }
}

.search-form {
  display: flex;
  justify-content: space-around;
}

.floating-label {
  position: relative;
  margin-bottom: 15px;

  input {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #bcc2ca;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #f8fafc;

    &:hover {
      border-color: #364152;
    }

    &:focus {
      border-color: #2196f3;
      outline: none;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      top: -10px;
      left: 8px;
      font-size: 0.75rem;
      color: #2196f3;
    }
  }

  label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f8fafc;
    padding: 0 5px;
    color: #aaa;
    transition: 0.2s;
    pointer-events: none;

    .label-content {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }
}

.deleteButton {
  background-color: #b9074d;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #97053f;
  }
}

.message {
  padding: 20px;
  background-color: #1d6f95;
  color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

.search-button {
  margin-bottom: 15px;
  width: 8%;
  margin-right: 10px;
  padding: 11px;
  font-size: 14px;
  cursor: pointer;
  background-color: #fec46c;
  color: black;
  border: none;
  border-radius: 4px;
  align-self: center;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
}

.btn-add {
  background-color: #fec46c;
  color: black;
  padding: 3px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: #fcad36;
  }
}
