.block {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fcf2d4;

    margin-bottom: 10px;

    &.small {
      width: calc(33% - 10px);
    }

    &.medium {
      width: calc(50% - 10px);
    }

    &.large {
      width: calc(100% - 10px);
    }

    h3 {
      font-size: 17px;
      font-weight: 700;
      color: #1d6f95;
      text-align: center;
    }
    h5 {
      font-size: 12px;
      text-align: center;
      color: #333;
      margin-bottom: 10px;
    }
}