.repiquage-container {
  text-align: center;
}

.magasin-info h1 {
  font-size: 32px;
  margin: 10px 0;
}

.magasin-info h2 {
  font-size: 24px;
  margin: 5px 0;
  color: #555;
}

.valider-container {
  margin-top: 40px;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.repiquage-image {
  max-width: 25%;
  height: auto;
  margin: 20px 0;
  border: 5px solid #f0a500;
  border-radius: 10px;
  cursor: pointer;
}

.repiquage-image-zoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1000;
  cursor: pointer;
}

.valider-container p {
  font-size: 18px;
  margin-top: 10px;
  color: #555;
  line-height: 1.6;
}

.attente-container {
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.options {
  margin: 20px 0;
}

.options label {
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
}

.comment-section {
  margin: 20px 0;
}

.comment-section textarea {
  width: 80%;
  height: 100px;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.submit-button {
  background-color: #007bff;
  color: white;
  width: 10%;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(75, 181, 252);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
}
