@import '/src/styles/global.scss';

.accueil-ope-card {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: fit-content;

  .accueil-ope {
    flex: 1 0 200px;

    border-radius: 5px;
    max-width: 200px;
    box-shadow: 0 5px 5px #8d8d8d;
    box-sizing: border-box;
    overflow: hidden;

    .accueil-ope-header {
      text-align: center;
      padding: 0.5em 1em;
      background-color: $color-primary;
      color: $color-background;
    }

    .accueil-ope-img img {
      display: block;
      width: 100%;
    }
  }
}

.com-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgba(50, 50, 50, 0.8);


  .com-popup-content {
    display: flex;
    justify-content: center;
    border: 1px solid $color-primary;
    border-radius: 5px;
    width: 50%;

    &>div {
      width: 50%;
      background-color: white;
      padding: 20px;
    }

    .com-popup-content-block-left {
      text-align: center;

      img {
        width: 100%;
        border: 1px solid $color-primary;
      }
    }

    .com-popup-date {
      margin: 10px auto;
      text-align: center;
    }
  }

  .pdf-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px 10px;

    gap: 10px;

    border: 1px solid $color-primary;
    color: red!important;
  }

  .com-popup-close-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .com-popup-links-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
}
