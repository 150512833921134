.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  position: relative;
}

.confirmation-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.confirmation-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.confirmation-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.confirmation-modal-body {
  padding: 10px 0;
}

.confirmation-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin-top: 10px;
  gap: 15px;
}

.confirmation-modal-footer button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.confirmation-delete-button {
  background-color: #d9534f;
  color: white;
}
