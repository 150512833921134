// src/styles/skeleton.scss
.skeleton {
  background-color: #ddd;
  border-radius: 4px;
  display: inline-block;
  height: 16px;
  line-height: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -150%;
  height: 100%;
  width: 150%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.5) 50%,
    transparent 100%
  );
  animation: loading 1.2s infinite;
}

@keyframes loading {
  0% {
    left: -150%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 150%;
  }
}
.space-small {
  height: 5px;
}
.space-medium {
  height: 10px;
}
.skeleton-title {
  height: 20px;
  width: 50%;
  margin: 0 auto 10px auto;
}
.skeleton-text {
  height: 10px;
  width: 80%;
  margin-bottom: 10px;
}

.skeleton-select {
  height: 25px;
  width: 100%;
  margin-bottom: 10px;
}

.skeleton-radio {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.skeleton-textarea {
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
}
.skeleton-table {
  height: 70px;
  width: 100%;
  margin-bottom: 10px;
}

.skeleton-button {
  height: 30px;
  width: 100px;
  margin: 10px auto;
  display: block;
  border-radius: 4px;
}
