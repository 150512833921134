@import '/src/styles/global.scss';

$color-primary: rgba($color-primary, 0.8);
$size-bezel: 0.5em;
$size-radius: 4px;

.selection-produits {
  overflow: auto;

  .selection-produits__head {
    @include flex-column-center();
    h3 {
      @include title-horrizontal-bar(
        $color-primary,
        $color-secondary
      );
    }
    .bdc-tuto {
      display: flex;
      flex-direction: column;
      width: fit-content;
      gap: 1em;
      font-size: 0.75em;
      font-weight: 500;
      color: $color-primary;
      background-color: rgba($color-primary, 0.3);
      padding: 1em 2em;
      margin-bottom: 2.5rem;
      border-radius: 3px;
      // border: 1px solid black;
      span {
        text-align: center;
        font-size: 1.1em;
        font-weight: 600;
      }
      ul {
        li {
          div {
            margin: 0.5em;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .products-actions {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: flex-start;
    font-size: 0.75em;

    // .input {
    //   @include input-with-label(
    //     $size-bezel,
    //     $size-radius,
    //     $color-primary,
    //     $color-primary,
    //     $color-white
    //   );
    // }

    // .select {
    //   @include select-with-label(
    //     $size-bezel,
    //     $size-radius,
    //     $color-primary,
    //     $color-primary,
    //     $color-white
    //   );
    //   min-width: 120px;
    // }
  }

  .btn-see-cart {
    cursor: pointer;
    line-height: 1.2;
    height: fit-content;
    width: fit-content;
    padding: calc($size-bezel * 1.5) $size-bezel * 2;

    gap: 0.5em;
    //font-size: 0.85em;
    font-size: 0.80rem;
    white-space: nowrap;

    @include btn(
      $color-white,
      $color-primary,
      $color-white,
      $color-accent
    );
    border: none;

    .btn-icon {
      font-size: 1.5em;
    }

    &.fixed-button {
      position: fixed;
      bottom: 20px;
      right: 220px;
      z-index: 1000;
      transition: 'all 0.3s ease-in-out';
      @media screen and (max-width:620px) {
        right: 20px;
      }
    }
    // padding: 0.5em 1em;
  }

  .MuiOutlinedInput-root {
    width: 9rem;
    text-align: right;
  }
  .products-filter {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75em;
    gap: 1rem;
  }

  .products-list {
    margin-top: 1em;
    padding: 1rem;
    @include flex-column-center();
    gap: 1em;
  }

  .products-list__loader {
    margin-top: 5em;
  }

  ::-webkit-scrollbar-track {
    background-color: (rgba($color-white, 0.4));
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-primary;
  }
}

@media screen and (max-width: 1024px) {
  .selection-produits {
    .products-actions {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      gap: 1em 0px;
      grid-template-areas:
        'btn-see-cart'
        'products-filter';

      .select {
        width: 100%;
      }
    }

    .products-filte {
      grid-area: products-filte;
    }

    .btn-see-cart {
      grid-area: btn-see-cart;
    }
  }
}
