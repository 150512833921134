.year-selector {
  margin-bottom: 20px;
  label {
    margin-right: 10px;
  }
  select {
    padding: 5px;
    font-size: 16px;
  }
}

.table-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  .table-engagement {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    th {
      background-color: #1d6f95;
      color: white;
      text-align: center;
    }

    tr:hover {
      background-color: #ddd;
    }

    .month-row {
      background-color: #ffb749;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      td {
        text-align: center;
      }
      &:hover {
        background-color: #ffb749;
      }
    }
  }
  .bon-de-commande-btn {
    background-color: #1d6f95;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;

    cursor: pointer;
    text-align: center;
    font-size: 14px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #134861;
    }
  }
  .center-text {
    text-align: center;
  }
}
