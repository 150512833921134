/* Conteneur principal */
.table-mes-commandes {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Tableau stylisé */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
}

/* En-tête du tableau */
.styled-table thead {
  background-color: #1d6f95; /* Couleur bleue */
}

.styled-table thead th {
  padding: 12px 15px;
  text-align: left;
  font-weight: 600;
  color: #ffffff; /* Texte blanc pour le contraste */
  border-bottom: 2px solid #1d6f95;
}

/* Corps du tableau */
.styled-table tbody tr {
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  transition: background-color 0.2s ease;
}

.styled-table tbody tr:hover {
  background-color: #f9f9f9;
}

.styled-table tbody td {
  padding: 12px 15px;
  color: #333333;
}

/* Bouton de détail */
.detailButton {
  background-color: #ffb749; /* Couleur orange */
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.detailButton:hover {
  background-color: #e0a437;
}


// Modal styles
.modal-commande {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    width: 50%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    .form-actions {
      display: flex;
      justify-content: center;

      .close-button {
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.2s;

        &.close-modal-btn {
          background-color: #ff4747;
          color: #fff;

          &:hover {
            background-color: #e02525;
          }
        }
      }
    }
  }
}
.order-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 30px;
  margin-bottom: 20px;
}

.order-info-item {
  display: flex;
  align-items: center;
}

.order-info-icon {
  margin-right: 10px;
  color: #1d6f95;
  font-size: 20px;
}

.order-info-label {
  font-weight: bold;
  margin-right: 5px;
}

.order-info-value {
  color: #333;
}

.commande-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
}

.commande-table thead {
  background-color: #1d6f95;
  color: #ffffff;
}

.commande-table thead th {
  padding: 12px;
  text-align: left;
}

.commande-table tbody tr {
  border-bottom: 1px solid #dddddd;
  transition: background-color 0.2s ease;
}

.commande-table tbody tr:hover {
  background-color: #f9f9f9;
}

.commande-table tbody td {
  padding: 12px;
}

.total-row {
  font-weight: bold;
}

.total-row td {
  background-color: #f7f7f7;
}
