.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  position: relative;
}

.confirmation-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 10px;
  gap: 30px;
}

.confirmation-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.confirmation-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.confirmation-modal-body {
  padding: 10px 0;
}

.confirmation-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin-top: 10px;
  gap: 15px;

  button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .confirmation-cancel-button {
    background-color: #f0f0f0;
    color: #333;
  }

  .confirmation-add-button {
    background-color: #1d6f95;
    color: white;
    &:hover {
      background-color: #055275;
    }
  }
}

.floating-label {
  position: relative;
  margin-bottom: 15px;

  textarea {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #bcc2ca;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #f8fafc;
    resize: none;

    &:hover {
      border-color: #364152;
    }

    &:focus {
      border-color: #2196f3;
      outline: none;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      top: -10px;
      left: 8px;
      font-size: 0.75rem;
      color: #2196f3;
    }
  }

  label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f8fafc;
    padding: 0 5px;
    color: #aaa;
    transition: 0.2s;
    pointer-events: none;
  }
}

.error-message {
  color: red;
}
