.produit-card {
  position: relative;
  width: 250px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  .image-container,
  .produit-info {
    flex: 1;
  }
  .image-container {
    position: relative;

    .promo-label {
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: #d51a58;
      padding: 5px 10px;
      color: #fff;
      font-weight: bold;
      border-radius: 3px;
      z-index: 10;
    }
  }

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .produit-info {
    padding: 15px;

    .price,
    .old-price,
    .promo-price {
      display: inline-block;
      vertical-align: middle;
    }

    .old-price {
      font-size: 1.1em;
      text-decoration: line-through;
      color: #888;
      margin-right: 5px;
    }

    .promo-price {
      font-size: 1.2em;
      font-weight: bold;
      color: #d51a58;
    }
    h2 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #333;
    }

    p.description {
      font-size: 0.9em;
      color: #777;
      margin-bottom: 10px;
    }

    p.price {
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 10px;
      color: #2c3e50;
    }

    p.condition {
      font-size: 0.85em;
      color: #555;
      margin-bottom: 10px;
    }

    p.promo {
      font-size: 1.1em;
      color: #d51a58;
      text-decoration: line-through;
      margin-right: 5px;
      display: inline-block;
    }

    p.shipping {
      font-size: 0.85em;
      color: #5bc0de;
      margin-bottom: 10px;
    }

    .detail-btn {
      margin-top: 10px;
      background-color: #5bc0de;
      color: #fff;
      border: none;
      padding: 8px 12px;
      border-radius: 5px;
      font-size: 0.9em;
      font-weight: 600;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: #49a6c2;
      }
    }
  }
}
