@import "/src/styles/global.scss";

.footer {
  background-color: $color-primary;
  height: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 300;
    text-align: center;
    color: $color-white;
  }
  ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  img {
    height: 40px;
  }

  .title {
    text-transform: uppercase;
    font-weight: 400;
    color: $color-white;
  }
}
