.panier {
  font-size: 16px;
  font-family: "Arial", sans-serif;
}

.titre-panier {
  margin-bottom: 1.5rem;
  width: 100%;

  height: fit-content;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #1d6f95;
  display: flex;
  align-items: center;
  gap: 2rem;
  &:before {
    content: " ";
    flex: 1 1;
    position: relative;
    height: 2px;
    background-color: #ffb749;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
  &:after {
    content: " ";
    flex: 1 1;
    position: relative;
    height: 2px;
    background-color: #ffb749;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
}
.cart-main-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding-left: 40px;
  padding-right: 40px;

  .cart-total-container {
    position: relative;
    flex-direction: column;
    width: 100%;
    min-height: 40vh;
    max-height: 40vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e5e5;
    overflow-y: auto;

    .item-detail {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .item-title {
        color: rgb(177, 177, 177);
      }

      .item-price {
        color: rgb(177, 177, 177);
      }
    }
    .separator {
      height: 2px;
      background-color: rgb(211, 211, 211);
      margin: 20px 0;
    }
    .cart-total {
      font-size: 24px;
      font-weight: bold;
      color: #000;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .cart-buttons {
      flex-direction: column;
      gap: 10px;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      display: flex;
      justify-content: space-between;

      .clear-btn,
      .checkout-btn {
        flex: 1;
        margin: 0 10px;
        padding: 10px 10px;
        border: none;
        border-radius: 4px;
      }
      .clear-btn {
        background-color: #ff4747;
        color: #fff;
        transition: background-color 0.2s;

        &:hover {
          background-color: #e02525;
        }
      }

      .checkout-btn {
        background-color: #2aa770;
        color: #fff;

        transition: background-color 0.2s;

        &:hover {
          background-color: #218838;
        }
      }
    }
  }
  .cart-details-wrapper {
    width: 25%;

    .cart-details-container {
      top: 20px;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: 175px;
      background-color: #fff;
      padding-top: 30px;
      padding-left: 20px;
      border-radius: 5px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border: 1px solid #e5e5e5;
      overflow-y: auto;

      .icon-text-item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
      }

      .item-text {
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
  .products {
    width: 75%;
    overflow-y: auto;
    flex-direction: column;
    display: flex;
    gap: 20px;

    .product-card {
      position: relative;
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding: 15px;
      padding-right: 20%;
      background-color: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      .remove-btn {
        position: absolute;
        top: 0px;
        right: 10px;
        background-color: #ff4747;
        color: #fff;
        padding: 5px 8px;
        border: none;
        border-radius: 4px;
        margin-top: 10px;
        transition: background-color 0.2s;

        &:hover {
          background-color: #e02525;
        }
      }
      .product-image-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .product-image {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      .product-info {
        flex: 2;
        padding: 0 15px;

        h3 {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }

      .product-actions {
        flex: 4;
        display: flex;
        align-items: center;

        .product-price-container {
          flex: 3;
          .product-price-label {
            margin-left: 50%;
            font-weight: bold;
            padding-bottom: 10px;
          }
          .product-price {
            font-size: 20px;
            color: #333;
            margin-left: 40%;
            font-weight: bold;
          }
        }

        .quantity-label {
          font-weight: bold;
          color: #000;
        }
        .quantity-container {
          display: flex;
          border: 1px solid #e0e0e0;
          align-items: center;
          margin-left: 10%;
          .quantity-value {
            flex: 1;
            text-align: center;
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            padding: 10px 10px;
            color: #000;
            font-weight: bold;
            background-color: #ffffff;
          }

          .quantity-btn {
            background-color: #f0f0f0;
            color: #000;
            border: 1px solid #e0e0e0;
            padding: 5px 10px;
            font-size: 20px;
            cursor: pointer;
            transition: background-color 0.2s;
          }

          .quantity-btn:hover {
            background-color: #d4d4d4;
          }

          .quantity-btn.decrement {
            border-right: 1px solid #00000000;
          }

          .quantity-btn.increment {
            border-left: 1px solid #00000000;
          }
        }
      }
    }
  }
}
// Modal
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  width: 500px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 8px;
    }

    input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .close-modal-btn,
    .submit-btn {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.2s;
    }

    .close-modal-btn {
      background-color: #ff4747;
      color: #fff;

      &:hover {
        background-color: #e02525;
      }
    }

    .submit-btn {
      background-color: #2aa770;
      color: #fff;

      &:hover {
        background-color: #218838;
      }
    }
  }
  .comment-textarea {
    border: 1px solid #ccc;
    padding: 8px 12px;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
    border-radius: 4px;
  }

  .form-group div {
    display: flex;
  }

  .form-group div input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-right: 20px;
  }

  .form-group div label {
    flex-grow: 1;
  }

  hr {
    border: 0;
    border-top: 1px solid #e0e0e0;
    margin: 15px 0;
  }
  .obligatory-note {
    margin-top: -10px;
    margin-bottom: -5px;
    font-size: 0.9em;
    color: #888;
  }
}

.success-banner {
  background-color: #4caf50; // couleur vert
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}
.error-banner {
  background-color: #f44336; // couleur rouge
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.empty-banner {
  background-color: #f7f7f7;
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.empty-banner a {
  color: #007bff;
  text-decoration: none;
  margin-left: 5px;
}
.continue-shopping-btn {
  background-color: #2d2d2d;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  span {
    margin-left: 10px;
  }
}
